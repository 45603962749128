import React, { useState } from "react";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import { Button, Col, Input, List, Modal, Row, Spin, Tabs } from "antd";
import rmtFront from "../../assets/images/rmt-front.jpg";
import rmtSide from "../../assets/images/rmt-side.jpg";
import rmtBack from "../../assets/images/rmt-back.jpg";

export default function RekamMedisTubuh({ datas, getEmrData, userCode }) {
    const [activeTab, setActiveTab] = useState("front");
    const [records, setRecords] = useState({ front: [], sides: [], back: [] });
    const [modalVisible, setModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [noteInput, setNoteInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleImageClick = e => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const latestId = datas && datas[activeTab]?.length
            ? datas[activeTab][datas[activeTab].length - 1].id
            : 0;

        const newRecord = {
            id: latestId + 1,
            x: (x / rect.width) * 100,
            y: (y / rect.height) * 100,
            note: ""
        };

        setCurrentRecord(newRecord);
        setModalVisible(true);
        setIsEditing(false);
    };

    const handleNoteSubmit = () => {
        if (noteInput.trim()) {
            setIsLoading(true);
            const updatedDatas = { ...datas };

            if (isEditing) {
                updatedDatas[activeTab] = updatedDatas[activeTab].map(record =>
                    record.id === currentRecord.id
                        ? { ...record, note: noteInput }
                        : record
                );
            } else {
                if (!updatedDatas[activeTab]) {
                    updatedDatas[activeTab] = [];
                }
                updatedDatas[activeTab].push({
                    ...currentRecord,
                    note: noteInput
                });
            }

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    rekam_medis_tubuh: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setIsLoading(false);
                    setModalVisible(false);
                    setNoteInput("");
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    const handleEditRecord = record => {
        setCurrentRecord(record);
        setNoteInput(record.note);
        setModalVisible(true);
        setIsEditing(true);
    };

    const handleDeleteRecord = (tabKey, recordId) => {
        setIsLoading(true);
        const updatedDatas = { ...datas };
        updatedDatas[tabKey] = updatedDatas[tabKey].filter(
            record => record.id !== recordId
        );

        apiCall(
            endpoint.GET_CUSTOMER_EMR,
            "put",
            {
                user_code: userCode,
                rekam_medis_tubuh: updatedDatas
            },
            result => {
                getEmrData(userCode);
                setIsLoading(false);
            },
            err => {
                ErrorHandler.handleGeneralError(err);
                setIsLoading(false);
            }
        );
    };

    function showDeleteConfirm(tabKey, recordId) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                handleDeleteRecord(tabKey, recordId);
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    const getImageSource = side => {
        switch (side) {
            case "front":
                return rmtFront;
            case "sides":
                return rmtSide;
            case "back":
                return rmtBack;
            default:
                return rmtFront;
        }
    };

    const renderImage = side => (
        <div
            style={{
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                maxWidth: "100vw",
                padding: "10px 0"
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "550px",
                    height: "500px"
                }}
            >
                <img
                    src={getImageSource(side)}
                    alt={`${side} view`}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                    }}
                    onClick={handleImageClick}
                />
                {datas &&
                    datas[side] &&
                    datas[side].map(record => (
                        <div
                            key={record.id}
                            style={{
                                position: "absolute",
                                left: `${record.x}%`,
                                top: `${record.y}%`,
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                background: "rgba(255, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontSize: "12px"
                            }}
                        >
                            {record.id}
                        </div>
                    ))}
            </div>
        </div>
    );

    return (
        <Spin spinning={isLoading}>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Tabs activeKey={activeTab} onChange={setActiveTab}>
                        <Tabs.TabPane tab="Depan" key="front">
                            {renderImage("front")}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Samping" key="sides">
                            {renderImage("sides")}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Belakang" key="back">
                            {renderImage("back")}
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        {["front", "sides", "back"].map(side => (
                            <List
                                key={side}
                                header={
                                    <h3>
                                        {side === "front"
                                            ? "Depan"
                                            : side === "sides"
                                            ? "Samping"
                                            : "Belakang"}
                                    </h3>
                                }
                                dataSource={
                                    datas && datas[side] ? datas[side] : []
                                }
                                renderItem={record => (
                                    <List.Item key={record.id}>
                                        {`${record.id}. ${record.note}`}
                                        <Button
                                            type="text"
                                            icon="edit"
                                            onClick={() =>
                                                handleEditRecord(record)
                                            }
                                            disabled={activeTab !== side}
                                            style={{ marginLeft: "1em" }}
                                            size="small"
                                        />
                                        <Button
                                            type="text"
                                            icon="delete"
                                            onClick={() =>
                                                showDeleteConfirm(
                                                    side,
                                                    record.id
                                                )
                                            }
                                            disabled={activeTab !== side}
                                            style={{ marginLeft: "1em" }}
                                            size="small"
                                        />
                                    </List.Item>
                                )}
                            />
                        ))}
                    </Row>
                </Col>

                <Modal
                    title="Enter Description"
                    visible={modalVisible}
                    onOk={handleNoteSubmit}
                    onCancel={() => {
                      setModalVisible(false);
                      setNoteInput("");
                    }}
                    maskClosable={false}
                >
                    <Input
                        value={noteInput}
                        onChange={e => setNoteInput(e.target.value)}
                        placeholder="Enter note"
                    />
                </Modal>
            </Row>
        </Spin>
    );
}

