import React, { useState } from "react";
import uuid from "uuid/v4";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import {
    Button,
    Descriptions,
    Empty,
    Input,
    Modal,
    Select,
    Spin,
    Table
} from "antd";
import moment from "moment";

// TODO: form validation - tanya pak rolando mana yg required mana yg tidak
export default function RiwayatAlergi({ datas, getEmrData, userCode }) {
    const initialFormState = {
        namaAlergi: "",
        diagnosa: "",
        catatanDokter: "",
        rekamMedisTubuh: "",
        suratMenyurat: ""
    };
    const [formMode, setFormMode] = useState("add");
    const [showAllergyModal, setShowAllergyModal] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = key => e => {
        const value = e.target ? e.target.value : e; // Handle Select value
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleOk = () => {
        setIsLoading(true);

        const currentDatas = Array.isArray(datas) ? datas : [];

        if (formMode === "add") {
            const newEntry = {
                ...formData,
                uuid: uuid(),
                createdAt: new Date().toISOString()
            };

            const newDatas = [...currentDatas, newEntry];

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    riwayat_alergi: newDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowAllergyModal(false);
                    setFormData(initialFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        } else {
            const updatedDatas = currentDatas.map(entry =>
                entry.uuid === formData.uuid
                    ? {
                          ...entry,
                          ...formData,
                          updatedAt: new Date().toISOString()
                      }
                    : entry
            );

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    riwayat_alergi: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowAllergyModal(false);
                    setFormData(initialFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    const handleCancel = () => {
        setShowAllergyModal(false);
        setFormData("");
    };

    const handleEdit = record => {
        setFormData(record);
        setFormMode("edit");
        setShowAllergyModal(true);
    };

    const handleView = record => {
        setFormData(record);
        setFormMode("view");
        setShowAllergyModal(true);
    };

    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                setIsLoading(true);
                const updatedDatas = datas.filter(
                    entry => entry.uuid !== record.uuid
                );

                apiCall(
                    endpoint.GET_CUSTOMER_EMR,
                    "put",
                    {
                        user_code: userCode,
                        riwayat_alergi:
                            updatedDatas.length > 0 ? updatedDatas : "{}"
                    },
                    result => {
                        getEmrData(userCode);
                        setIsLoading(false);
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                        setIsLoading(false);
                    }
                );
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <Button
                    htmlType="button"
                    size="default"
                    type="primary"
                    onClick={() => {
                        setFormMode("add");
                        setShowAllergyModal(true);
                    }}
                >
                    Buat Riwayat Alergi Baru
                </Button>
            </div>

            <h4>Riwayat Alergi Pelanggan</h4>
            <Spin spinning={isLoading}>
                {Array.isArray(datas) && datas.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={datas}
                            rowKey={record => record.uuid}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Nama Alergi"
                                dataIndex="namaAlergi"
                                key="namaAlergi"
                            />
                            <Table.Column
                                title="Diagnosa"
                                dataIndex="diagnosa"
                                key="diagnosa"
                            />
                            <Table.Column
                                title="Tanggal Input"
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY"
                                    )
                                }
                            />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => handleView(record)}
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => handleEdit(record)}
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Riwayat Alergi"
                        : formMode === "edit"
                        ? "Edit Riwayat Alergi"
                        : "View Riwayat Alergi"
                }
                visible={showAllergyModal}
                onOk={handleOk}
                onCancel={handleCancel}
                width="75%"
                okText={
                    formMode === "add"
                        ? "Add Riwayat Alergi"
                        : formMode === "edit"
                        ? "Edit Riwayat Alergi"
                        : "View Riwayat Alergi"
                }
                okButtonProps={{ disabled: formMode === "view" }}
                maskClosable={false}
            >
                <Spin spinning={isLoading}>
                    <Descriptions
                        bordered
                        layout={
                            window.innerWidth <= 576 ? "vertical" : "horizontal"
                        }
                        column={1}
                    >
                        <Descriptions.Item label="Nama Alergi">
                            {formMode === "view" ? (
                                <h3>{formData.namaAlergi}</h3>
                            ) : (
                                <Select
                                    value={formData.namaAlergi}
                                    style={{ width: "100%" }}
                                    onChange={handleInputChange("namaAlergi")}
                                >
                                    <Select.Option value="dingin">
                                        Dingin
                                    </Select.Option>
                                    <Select.Option value="antibiotik">
                                        Antibiotik
                                    </Select.Option>
                                    <Select.Option value="debu">
                                        Debu
                                    </Select.Option>
                                    <Select.Option value="suhu">
                                        Suhu
                                    </Select.Option>
                                </Select>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Diagnosa">
                            {formMode === "view" ? (
                                <h3>{formData.diagnosa || "No Diagnosa"}</h3>
                            ) : (
                                <Input
                                    placeholder="Diagnosa"
                                    value={formData.diagnosa}
                                    onChange={handleInputChange("diagnosa")}
                                />
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Catatan Dokter">
                            {formMode === "view" ? (
                                <h3>
                                    {formData.catatanDokter || "No Catatan"}
                                </h3>
                            ) : (
                                <Input
                                    placeholder="Catatan Dokter"
                                    value={formData.catatanDokter}
                                    onChange={handleInputChange(
                                        "catatanDokter"
                                    )}
                                />
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Rekam Medis Tubuh">
                            {formMode === "view" ? (
                                <h3>
                                    {formData.rekamMedisTubuh ||
                                        "No Rekam Medis"}
                                </h3>
                            ) : (
                                <Input
                                    placeholder="Rekam Medis Tubuh"
                                    value={formData.rekamMedisTubuh}
                                    onChange={handleInputChange(
                                        "rekamMedisTubuh"
                                    )}
                                />
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Surat Menyurat">
                            {formMode === "view" ? (
                                <h3>
                                    {formData.suratMenyurat ||
                                        "No Surat Menyurat"}
                                </h3>
                            ) : (
                                <Input
                                    placeholder="Surat Menyurat"
                                    value={formData.suratMenyurat}
                                    onChange={handleInputChange(
                                        "suratMenyurat"
                                    )}
                                />
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </Modal>
        </>
    );
}
